<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <div class="col-4 mb-5 mt-2 text-center custom-offset-md-4">
                    <Dropdown v-model="form.class_id" :options="selectedClass" @change="selectAddAppNumFetch" optionLabel="name" placeholder="Select a Class" class="full-width" :filter="true" style="width: 100%">
                        <template v-slot:option="{ option }">
                            <div>
                                <i v-if="selectedClass === option" class="pi pi-check" style="float: right"></i>
                                {{ option.name }}
                            </div>
                        </template>
                    </Dropdown>
                </div>
                <div class="card p-fluid">
                    <DataTable v-model:editingRows="editingRows" :value="allSubjects" editMode="row" dataKey="id"
                            @row-edit-save="onRowEditSave" tableClass="editable-cells-table" tableStyle="min-width: 50rem">
                        <Column field="paper_code" header="Code" style="width: 20%">
                            <template #editor="{ data, field }">
                                <InputText v-model="data[field]" />
                            </template>
                        </Column>
                        <Column field="subject_title" header="Title" style="width: 20%">
                            <template #editor="{ data, field }">
                                <InputText v-model="data[field]" />
                            </template>
                        </Column>
                        <Column field="fee" header="Fee" style="width: 20%">
                            <template #editor="{ data, field }">
                                <InputText v-model="data[field]" />
                            </template>
                        </Column>
                        <Column field="late_fee" header="Late Fee" style="width: 20%">
                            <template #editor="{ data, field }">
                                <InputText v-model="data[field]" />
                            </template>
                        </Column>
                       
                        <Column :rowEditor="true" style="width: 10%; min-width: 8rem" bodyStyle="text-align:center"></Column>
                    </DataTable>
                </div>
                 
               

            </div>
        </div>

        <Toast />
    </div>

</template>

<style>
.picklist .p-picklist-source-controls, .p-picklist-target-controls{
    display: none;
}
.custom-offset-md-4 {
    margin-left: 33.3333%;
}
.paper-card .p-listbox {
  border: none !important;
  box-shadow: none !important;
}
.paper-card{
  border: 1px solid #e0e0e0;
  box-shadow: none !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.custom-listbox {
  height: 300px;
  font-weight: 600;
}
</style>

<script>
export default {
    data() {
        return {
            selectedClass: null,
            form: new this.Form({
                class_id: '',
            }),
            selectedSubject: null,
            selectedClassId: null,
            allSubjects: null,
            searchAble: null,
            paper_2:null,
            selectedPapers2: null,
            columns: [
                { field: 'paper_code', header: 'ID' },
                { field: 'subject_title', header: 'Subject Title' },
                { field: 'fee', header: 'Paper Fee' },
                { field: 'late_fee', header: 'Late Fee' },
            ],
            classOptions: [{ name: 'MBBS Final Year' }, { name: 'MBBS 3rd Year' }, { name: 'MBBS 2nd Year' }, { name: 'BDS Final Year' }, { name: 'BDS 2nd Year' }, { name: 'BDS 1st Year' }],
        };
    },
    components: {},
    created() {},
    mounted() {
        this.getAllClasses();
    },
    methods: {
        selectAddAppNumFetch() {
            this.isDisabled = false;
            this.getRecords(this.form.class_id);
        },
        getAllClasses() {
            var Vthis = this;
            // this.loading = true;
            this.axios
                .post('api/get-class')
                .then((response) => {
                    this.selectedClass = response.data;
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                })
                .finally(() => {});
        },
        getRecords() {
            var Vthis = this;
            this.loading = true;
            this.selectedClassId = this.form.class_id;
            this.axios
                .post('api/get-subjects-for-fee', { class_id: this.form.class_id })
                .then((response) => {
                    this.allSubjects = response.data.allTableData;
                    this.loading = false;
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                })
                .finally(() => {});
        },
        // save_selected_subjects() {
           
        //     var Vthis = this;
        //    // this.loading = true;
        //     this.axios
        //         .post('api/store-class-subjects', { subject_lists: this.allSubjects,class_id: this.form.class_id, paper2_lists: this.selectedPapers2,})
        //         .then((response) => {
                  
        //             Vthis.showMessage('Saved successfully','success',); 
        //         })
        //         .catch((error) => {
        //             let message = Vthis.customError(Vthis.form.errors.has('error') ? Vthis.form.errors.get('error') : error  ); 
        //             Vthis.showMessage(message,'error'); 
        //         })
        //         .finally(() => {});
        // },
        filter_data_subjects() {
            if (this.searchAble) {
                var value = this.searchAble.toLowerCase();
                var sourceListItems = document.querySelectorAll('.p-picklist-source li');
                sourceListItems.forEach(function (item) {
                    var text = item.textContent.toLowerCase();
                    if (text.indexOf(value) > -1) {
                        item.style.display = 'block';
                    } else {
                        item.style.display = 'none';
                    }
                });
            }
        },
        onCellEditComplete(event) {
            let { data, newValue, field } = event;

            switch (field) {
                case 'quantity':
                case 'price':
                    if (this.isPositiveInteger(newValue)) data[field] = newValue;
                    else event.preventDefault();
                    break;

                default:
                    if (newValue.trim().length > 0) data[field] = newValue;
                    else event.preventDefault();
                    break;
            }
        },
        onRowEditSave(event) {
            let { newData, index } = event;

            this.allSubjects[index] = newData;
        },
    },
    computed: {
        isSubmitDisabled() {
            return !this.allSubjects || this.allSubjects.length === 0;
        },
    },

    watch: {

        allSubjects(newValue, oldValue) {
          this.paper_2 = this.allSubjects[1];
      // This function will be called when variable1 changes
      // You can perform actions here based on the changes
      //console.log(`variable1 changed from ${oldValue} to ${newValue}`);
    },
    
    },
};
</script>

<style lang="scss" scoped>
::v-deep(.editable-cells-table td.p-cell-editing) {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
}
</style>